<template>
  <div class="container">
    <div class="row">
      <h1>My bridgable RATs
        <button class="btn btn-primary" @click="getRats">Reload</button>
      </h1>
      <template v-if="info && info.length == 0">
        <p>You have not sent any $RATs to the bridge!</p>
        <p>Go to <a href="/wallet">Wallet</a> and send some!</p>
      </template>
      <template v-if="info && info.length > 0">
        <div class="container">
          <div class="row">
            <template v-for="nft in info" :key="nft.id">
              <div class="col-lg-2 col-md-3 col-sm-6 text-center rat-card">
                <router-link v-bind:to="'/rat/id/' + nft.nftNumber">
                  <img v-bind:src="'https://api.cryptor.at/original/rat/' + nft.nftNumber" class="img-fluid rat-image" /><br/>
                  <span style='height:60px;display:block;'>#{{nft.nftNumber}}</span>
                </router-link>
              </div>
            </template>
          </div>
        </div>

        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="card mb-3">
                <div class="card-body">
                  <strong>My CashTokens address:</strong><br/>
                  <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon3">To:</span>
                    <input type="text" v-model="userCashTokenAddress" class="form-control" placeholder="" />
                  </div>
                  <button class="btn btn-primary" @click="bridgeRats">Send {{info.length}} RATs to CT!</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import {ethers} from "ethers";

  const bridgeUrl = 'https://shadowkite.io:3050'

  export default {
    data() {
      return {
        connectionStatus: '',
        bridgeInfo: '',
        validTokenAddress: null,
        info: null,
        userCashTokenAddress: '',
        successfulTx: null
      }
    },
    mounted() {
      this.getRats()
    },
    watch: {
      '$store.state.address'() {
        this.getRats()
      }
    },
    methods: {
      bridgeRats: async function() {
        let provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        provider.send("eth_requestAccounts", []).then(async (accounts) => {
          const signature = await provider.getSigner().signMessage(this.userCashTokenAddress);
          const rawResponse = await fetch(bridgeUrl + '/signbridging', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              signature,
              sbchOriginAddress: accounts[0].toLowerCase(),
              destinationAddress: this.userCashTokenAddress
            })
          });
          const { txId } = await rawResponse.json();
          this.successfulTx = txId
          await this.getRats()
        })
      },
      getCTAddress: async function () {
        if(!this.userCashTokenAddress) {
          try {
            const rawResponse = await fetch(bridgeUrl + '/ct-address/' + this.$store.state.address)
            const response = await rawResponse.json();
            console.log(response)
            this.userCashTokenAddress = response.address;
          } catch (error) {
            console.log('Error')
          }
        }
      },
      getRats: async function() {
        await this.getCTAddress()
        try {
          const rawResponse = await fetch(bridgeUrl + '/address/' + this.$store.state.address)
          if(!rawResponse.ok)
            alert("failed to fetch...")
          this.info = await rawResponse.json();
        } catch (error) {
          alert("failed to fetch...")
        }
      }
    }
  }
</script>